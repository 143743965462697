.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 2s ease-in-out;
  }
  
  .fade-out {
    animation: fadeOut 2s ease-in-out 2500ms;
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }