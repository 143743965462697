html, body {
  height: 100%;
}

@font-face {
  font-family: 'BrandingSemilight';
  src: url('./assets/fonts/Branding\ Semilight.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'BrandingSemilight', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: BrandingSemilight, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: rgb(232, 232, 232);
}